export const envConfig = {
  socketAddress: 'wss://homeawayscore.com:3001',
  firebaseConfig: {
    apiKey: "AIzaSyAH8X3V-aqazC95iiyBxTKbu-2RlRQvqhU",
    authDomain: "zawodtyper-170d8.firebaseapp.com",
    projectId: "zawodtyper-170d8",
    storageBucket: "zawodtyper-170d8.appspot.com",
    messagingSenderId: "753076353167",
    appId: "1:753076353167:web:6a4696ef2901d70d5598fa",
    measurementId: "G-PWK13QR8TN"
  },
  vapidKey: 'BGXP1ktfbrLohUnOn3RGdwa7HRAxh3NFTF2vTMRF8bE_Ue8LLNS4YUk3aSdHup0VLckK5Cp0yctW17Sccputflw',
  siteUrl: 'https://zawodtyper.pl/',
};